
// Layout
header {
  padding: rem-calc(30 0);
  margin-bottom: rem-calc(30);
  //background-color: #8FA68A;
  background-color: #BF383A;
  h1, .h5 {
    color: #F3EAC0;
    a {
      color: #F3EAC0;
    }
  }
  .menu a {
    color: #F2DEC4;
  }
}

footer {
  padding: rem-calc(30 0);
  background-color: #BF383A;
  color: #F3EAC0;
}

h1, h2, h3, h4, h5, h6, h7,
.h1, .h2, .h3, .h4, .h5, .h6, .h7 {
  a {
    color: #F37965;
  }
}


figure {
  margin-bottom: rem-calc(20);
}

.copyright {
  margin: rem-calc(20 0);
  font-size: rem-calc(12);
}


// Components
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: rem-calc(10);
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}